exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-almalinux-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/almalinux.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-almalinux-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-alpine-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/alpine.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-alpine-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anaconda-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/anaconda.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anaconda-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anaconda-r-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/anaconda-r.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anaconda-r-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anolis-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/anolis.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-anolis-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-archlinux-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/archlinux.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-archlinux-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-archlinuxcn-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/archlinuxcn.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-archlinuxcn-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-bioconductor-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/bioconductor.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-bioconductor-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-centos-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/centos.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-centos-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-centos-vault-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/centos-vault.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-centos-vault-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cpan-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/CPAN.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cpan-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cran-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/CRAN.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cran-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-crates-io-index-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/crates.io-index.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-crates-io-index-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ctan-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/CTAN.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ctan-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cygwin-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/cygwin.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-cygwin-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-debian-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/debian.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-debian-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-debian-security-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/debian-security.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-debian-security-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-deepin-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/deepin.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-deepin-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-docker-ce-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/docker-ce.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-docker-ce-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-elpa-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/elpa.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-elpa-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-elrepo-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/elrepo.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-elrepo-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-epel-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/epel.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-epel-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-fedora-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/fedora.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-fedora-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/gentoo.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-portage-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/gentoo-portage.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-portage-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-portage-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/gentoo-portage.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gentoo-portage-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-glibc-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/glibc.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-glibc-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gnu-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/gnu.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-gnu-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-homebrew-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/homebrew.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-homebrew-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ius-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/ius.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ius-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-kali-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/kali.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-kali-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-linux-git-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/linux.git.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-linux-git-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-linuxmint-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/linuxmint.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-linuxmint-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-llvm-apt-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/llvm-apt.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-llvm-apt-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-manjaro-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/manjaro.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-manjaro-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-mozilla-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/mozilla.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-mozilla-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-msys-2-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/msys2.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-msys-2-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-npm-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/npm.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-npm-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-opensuse-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/opensuse.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-opensuse-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-openwrt-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/openwrt.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-openwrt-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-packman-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/packman.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-packman-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-pypi-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/pypi.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-pypi-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-qt-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/qt.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-qt-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-raspberrypi-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/raspberrypi.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-raspberrypi-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-raspbian-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/raspbian.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-raspbian-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rocky-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/rocky.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rocky-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ros-2-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/ros2.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ros-2-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ros-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/ros.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ros-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rosdistro-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/rosdistro.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rosdistro-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rustup-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/rustup.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-rustup-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ubuntu-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/ubuntu.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ubuntu-mdx" */),
  "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ubuntu-ports-mdx": () => import("./../../../src/templates/mirror-doc.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/docs/zh/ubuntu-ports.mdx" /* webpackChunkName: "component---src-templates-mirror-doc-tsx-content-file-path-docs-zh-ubuntu-ports-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-en-221115-hello-mirror-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/news/en/221115_hello_mirror.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-en-221115-hello-mirror-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-zh-221115-hello-mirror-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/news/zh/221115_hello_mirror.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-zh-221115-hello-mirror-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-zh-240410-maintenance-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/news/zh/240410_maintenance.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-zh-240410-maintenance-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-zh-240605-removal-of-ius-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/news/zh/240605_removal_of_ius.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-zh-240605-removal-of-ius-mdx" */),
  "component---src-templates-news-tsx-content-file-path-news-zh-240624-removal-of-centos-legacy-mdx": () => import("./../../../src/templates/news.tsx?__contentFilePath=/builds/zju-mirror/mirror-front/news/zh/240624_removal_of_centos_legacy.mdx" /* webpackChunkName: "component---src-templates-news-tsx-content-file-path-news-zh-240624-removal-of-centos-legacy-mdx" */)
}

